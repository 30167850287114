import React from 'react'

const Image = ({ id, text, imageUrl, position, verticalAlign, width }) => {
    return (
        <div className="container page-block" id={id}>
            <div className={`img-block ${position}-${verticalAlign}`}>
                <img src={imageUrl} style={{ width: width }} />
                { !!text ? <p>{text}</p> : null }
            </div>
        </div>
    )
}

export default Image