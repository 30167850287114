import React, { Fragment, useRef } from 'react'

const Navigation = ({ title, color, backgroundColor, items }) => {

    const navRef = useRef();
    const handleScroll = (id) => {
        const element = $(`#${id}`)
        const navigation = navRef.current
        const navbar = $('.header')
        let pos = 0;

        if (element.length) { pos += element[0].offsetTop - navigation.clientHeight - parseInt(element.css('margin-top')) }
        if (navbar.length) { pos -= navbar.innerHeight() }

        const pagePreview = $('.page-preview')
        if (pagePreview.length) { pagePreview[0].scrollTo({ top: pos, behavior: "smooth" }) }
        else { window.scrollTo({ top: pos, behavior: "smooth" }) }
    }

    return (
        <div className="navigation container-sm" style={{ backgroundColor: backgroundColor, color: color }} ref={navRef}>
            { !!title ? (
                <h4 className="nav-title">{title}</h4>
            ) : null }
            <div className="nav-menu">
                { items.map((item, idx) => (
                    <Fragment key={idx}>
                        <div className="nav-item" onClick={() => { handleScroll(item.id) }}>
                            <span>{item.title}</span>
                        </div>
                        { idx !== items.length - 1 ? <span>|</span> : null }
                    </Fragment>
                ))}
            </div>
        </div>
    )
}

export default Navigation