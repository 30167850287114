import React from 'react'

const Separator = ({ size, color, backgroundColor }) => {

    return (
        <div className="container" style={{ backgroundColor: backgroundColor }}>
            <div style={{ borderTop: `${size} solid ${color}` }} />
        </div>
    )
}

export default Separator