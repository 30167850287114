import React, { Fragment, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import Renderer from 'components/Renderer'
import getLightboxes from '../components/api/getLightboxes'

const Page = ({content}) => {
    //Get Lightboxes
    const [lightboxes, setLightboxes] = useState([]);

    useEffect(() => {
        getLightboxes(content.slug).then(data => {
            setLightboxes(data);
        })
    }, []);

    return <Fragment>
        {
            content.map((c, idx) => <Renderer key={idx} lightboxes={lightboxes} {...c} />)
        }
    </Fragment>
}

document.addEventListener('DOMContentLoaded', () => {
    const root = document.getElementById('page-root');
    const DATA = JSON.parse(root.getAttribute('data'))

    if(!!root) {
        ReactDOM.render(
          <Page content={DATA} />,
          root,
        )
    }
})
