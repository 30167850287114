import React, { useEffect } from 'react'

const Text = ({ id, content, uuid }) => {

    useEffect(() => {
        const richText = document.getElementById(uuid);
        richText.innerHTML = content;
    }, [content]);

    return (
        <div className="container page-block" id={id}>
            <div className="rich-text-block" id={uuid} />
        </div>
    );
}

export default Text