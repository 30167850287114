import React from 'react'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Title = ({ id, title, linkType, linkText, linkUrl, openInNewTab }) => {
    return (
        <div className="container page-block" id={id}>
            <div className="title-block">
                <h2 className="heading">{title}</h2>
                { !!linkText ? (
                    <a 
                        href={linkUrl}
                        className={`link-to ${ linkType === 'button' ? 'btn btn-default' : '' }`}
                        target={ openInNewTab ? '_blank' : '' }
                    >
                        <span className={ linkType === 'link' ? 'underline' : '' }>{linkText}</span>
                        <ChevronRightIcon />
                    </a>
                ) : null }
            </div>
        </div>
    );
}

export default Title