import React, {Fragment} from 'react'
import Lightbox, { displayLightbox } from './blocks/Lightbox';

const Slide = ({ title, description, linkText, linkUrl, imageUrl, isActive, openInNewTab, lightbox={} }) => {
    const lightboxId = lightbox.id || null;
    return (
        <Fragment>
            <div
                className={`carousel-slide ${ !isActive ? 'transparent' : '' }`}
                style={{ backgroundImage: `url(${imageUrl})` }}
            >
                <div className="caption">
                    { !!title ? <h1 className="title">{title}</h1> : null }
                    { !!description ? <p className="description">{description}</p> : null }
                    { !!linkText ? (
                        <a
                            href={linkUrl}
                            className={`btn btn-white ${lightboxId ? 'lightbox-btn' : ''}`}
                            target={ openInNewTab ? '_blank': '' }
                            // data-lightbox-id={lightbox.id}
                            onClick={(e) => {
                                if (lightboxId){
                                    displayLightbox(e, lightboxId);
                                }
                            }}
                        >
                            {linkText}
                        </a>
                    ) : null }
                </div>
            </div>
            <Lightbox item={lightbox}/>
        </Fragment>
    );
}

export default Slide