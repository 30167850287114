import React, {useState, useEffect} from 'react'
import Slide from 'components/Slide'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Slider = ({ id, items, lightboxes=[] }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const changeSlide = (n) => {
        if(n > items.length - 1) {
            setCurrentSlide(0)
        } else if(n < 0) {
            setCurrentSlide(items.length - 1);
        } else {
            setCurrentSlide(n)
        }
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => changeSlide(currentSlide + 1), 8000)
        return () => clearTimeout(timeoutId)
    }, [currentSlide])

    return (
        <div className="carousel" id={id}>
            { items.map((slide, idx) => {
                let selectedLightbox = lightboxes.find(l => l.id === slide.lightboxId) || {};
                return (
                    <Slide key={idx}
                        isActive={idx === currentSlide}
                        title={slide.title}
                        description={slide.description}
                        linkText={slide.linkText}
                        linkUrl={slide.linkUrl}
                        imageUrl={slide.imageUrl}
                        openInNewTab={slide.openInNewTab}
                        lightbox={selectedLightbox} />
                )
            })}
            <a className="scroll-left" onClick={() => changeSlide(currentSlide - 1)}>
                <ChevronLeftIcon />
            </a>
            <a className="scroll-right" onClick={() => changeSlide(currentSlide + 1)}>
                <ChevronRightIcon />
            </a>
            <div className="dots">
                { items.map((_, idx) => (
                    <span key={`dot-${idx}`}
                            className={`dot ${ idx === currentSlide ? 'active' : '' }`}
                            onClick={() => changeSlide(idx)}>
                    </span>
                ))}
            </div>
        </div>
    );
}

export default Slider