import React from 'react'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Lightbox, { displayLightbox } from './Lightbox';

const CalloutBox = ({ id, subtitle, title, content, linkText, linkUrl, imageUrl,
                    openInNewTab, lightbox={} }) => {
    const lightboxId = lightbox.id || null;
    return (
        <div className="container page-block" id={id}>
            <a
                href={ !!linkUrl ? linkUrl : null }
                className={`callout-box ${lightboxId ? 'lightbox-btn' : ''}`}
                target={ openInNewTab ? '_blank' : '' }
                style={ lightboxId && { cursor: 'pointer' }}
                onClick={(e) => {
                    if (lightboxId) {
                        displayLightbox(e, lightboxId);
                    }
                }}
            >
                <img src={imageUrl} />
                <div className="text">
                    { !!subtitle ? <p className="subtitle">{subtitle}</p> : null }
                    { !!title ? <h2 className="title">{title}</h2> : null }
                    { !!content ? <p className="description">{content}</p> : null }
                    { !!linkText ? (
                        <div className="link-to">
                            <span className="underline">{linkText}</span>
                            <ChevronRightIcon />
                        </div>
                    ) : null }
                </div>
            </a>
            <Lightbox item={lightbox}/>
        </div>
    )
}

export default CalloutBox