import React, { useState } from 'react'
import CloseIcon from '@material-ui/icons/Close';

const Announcement = ({ content }) => {
    const [visible, setVisible] = useState(true);

    if(!visible) return null;

    return (
        <div className="announcement">
            { !!content ? <p>{content}</p> : null }
            <CloseIcon onClick={() => setVisible(false)} />
        </div>
    )
}

export default Announcement
