import React from 'react'

const Embed = ({ id, embedUrl, embedType, width, height, position }) => {

    const defaultSize = {
        'youtube': {
            'width': 560,
            'height': 315
        },
        'spotify': {
            'width': '100%',
            'height': 232
        },
        'gmaps': {
            'width': 600,
            'height': 450
        }
    }

    return (
        <div className="container page-block" id={id}>
            <div className={`embed-block ${embedType} ${position}`}>
                { !!embedUrl ? (
                    <iframe width={ !!width ? width : defaultSize[embedType]['width'] }
                            height={ !!height ? height : defaultSize[embedType]['height'] }
                            src={embedUrl}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                ) : null }
            </div>
        </div>
    )
}

export default Embed