import React from 'react'
import Lightbox, { displayLightbox } from './Lightbox';

const Callout = ({ id, imageUrl, width, linkUrl, position, bgImageUrl, bgWidth, caption,
                linkText, buttonUrl, openInNewTab, lightbox={} }) => {
    const lightboxId = lightbox.id || null;
    return (
        <div className="container page-block" id={id}>
            <div className={`callout-lr ${position}`}>
                <a
                    href={ !!linkUrl ? linkUrl : null }
                    style={{ flexBasis: width }}
                    target={ openInNewTab ? '_blank': '' }
                >
                    <img src={imageUrl} />
                </a>
                <div className="bg" style={{ flexBasis: bgWidth }}>
                    <img src={bgImageUrl} />
                    <div className="filter"></div>
                    <div className="text">
                        { !!caption ? <h4 className="caption">{caption}</h4> : null }
                        { !!linkText ? (
                            <a
                                className={`btn btn-white ${lightboxId ? 'lightbox-btn' : ''}`}
                                href={buttonUrl}
                                target={ openInNewTab ? '_blank': '' }
                                onClick={(e) => {
                                    if (lightboxId) {
                                        displayLightbox(e, lightboxId);
                                    }
                                }}
                            >
                                {linkText}
                            </a>
                        ) : null }
                    </div>
                </div>
                <Lightbox item={lightbox}/>
            </div>
        </div>
    );
}

export default Callout