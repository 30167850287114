import React, { Fragment } from "react";

export const displayLightbox = (e, lightboxId) => {
    const lightboxContainer = document.querySelector(`#lb-${lightboxId}`);
    if (lightboxContainer) {
        e.preventDefault();
        lightboxContainer.classList.add("show", "fade");
        document.body.classList.add('overflow-none');
    }
}

const Lightbox = ({item}) => {
    const closeLightbox = (lightboxId) => {
        const lightboxContainer = document.querySelector(`#lb-${lightboxId}.show`);

        if (lightboxContainer) {
            lightboxContainer.classList.remove("show", "fade");
            document.body.classList.remove('overflow-none');
            let iframe = lightboxContainer.querySelector('iframe');
            if (iframe){
                let iframeSrc = iframe.src;
                iframe.src = iframeSrc;
            }
        }
    }
    return (
        <Fragment>
            { item &&
                <div id={`lb-${item.id}`}
                    className="lightbox-container"
                    onClick={(e) => closeLightbox(item.id)}
                    >
                    <div className="content">
                        { item.image ?
                            <div>
                                <a href={item.image_link ? item.image_link : null }>
                                    <img src={item.image}
                                        height={item.meta.image_height}
                                        width={item.meta.image_width} />
                                </a>
                            </div>
                        : null }
                        { item.youtube_id ?
                            <div style={{ textAlign: 'center' }}>
                                <iframe src={item.youtube_id}
                                        height={item.meta.youtube_embed_height && item.meta.youtube_embed_height}
                                        width={item.meta.youtube_embed_width ? item.meta.youtube_embed_width : '80%'}
                                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                        allowFullScreen={true}
                                        style={!item?.meta?.youtube_embed_height ? { minHeight: '60vh' } : {}} />
                            </div>
                        : null }
                        <span className="material-icons close-btn"
                            onClick={(e) => closeLightbox(item.id)}>close</span>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default Lightbox