import React, { Fragment, useState, useEffect } from "react";
import axios from 'axios';
import Slider from "./Slider";
import Pagination from "../Pagination";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const PostBannerCard = ({item, isActive, isCarousel, style}) => {
    return (
        <a href={item.url}
            className={`featured ${isCarousel && 'carousel-slide'} ${!isActive ? 'transparent' : ''}`}
            style={style}>

            <img src={item.image} />
            <div className="text">
                <h3 className="title">{item.title}</h3>
                <p>{item.description}</p>
            </div>
        </a>
    )
}

const PostBanner = ({id, sources, numberOfItems, order, layout, color, backgroundColor, itemsPerPage}) => {
    const [items, setItems] = useState([]);
    const [totalItemsCount, setTotalItemsCount] = useState(numberOfItems);
    const [currentPage, setPage] = useState(1);
    const [currentSlide, setSlide] = useState(0);

    // Construct URL
    let urlParams = ""
    sources.forEach((source) => {
        urlParams += `sources[]=${source}&`;
    })
    urlParams += `number_of_items=${numberOfItems}&order=${order}`
    if (itemsPerPage) {
        urlParams += `&items_per_page=${itemsPerPage}&page=${currentPage}`
    }

    // Call API and set state
    const getItem = () => {
        axios.get(`/api/v1/item_display.json?${urlParams}`)
            .then((res) => {
                setItems(res.data.items);
                setTotalItemsCount(res.data.total_count);
            });
    }
    useEffect(getItem, [sources, numberOfItems, order, itemsPerPage, currentPage]);

    // Reset current page
    useEffect(() => {
        setPage(1);
        setSlide(0);
   }, [itemsPerPage, numberOfItems]);

   const isListLayout = layout === 'list';
   const enablePagination = isListLayout && itemsPerPage && totalItemsCount > itemsPerPage;

   const handlePageNumberChange = (page) => {
        setPage(page)
    };

    const handleSlideChange = (slide) => {
        var maxCount = (itemsPerPage && itemsPerPage > 1 ? Math.min(itemsPerPage, totalItemsCount) : totalItemsCount) - 1

        if (slide < 0) {
            setSlide(maxCount);
            return
        } else if (slide > (maxCount)) { //Get minimum count
            setSlide(0);
            return
        }
        setSlide(slide);
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => handleSlideChange(currentSlide + 1), 7000)
        return () => clearTimeout(timeoutId)
    }, [currentSlide])

    return (
        <div className="">
            <div className={`post-banner ${isListLayout ? 'list' : 'carousel'}`} id={id}>
                { items.map((item, idx) => {
                    return (
                        <PostBannerCard
                            key={idx}
                            item={item}
                            isActive={(isListLayout || idx === currentSlide)}
                            isCarousel={!isListLayout}
                            style={{backgroundColor: backgroundColor, color: color}} />
                    )
                })}

                { !isListLayout && (
                    <>
                        <a className="scroll-left" onClick={() => handleSlideChange(currentSlide - 1)}>
                            <ChevronLeftIcon />
                        </a>
                        <a className="scroll-right" onClick={() => handleSlideChange(currentSlide + 1)}>
                            <ChevronRightIcon />
                        </a>
                    </>
                )}
                
                { (enablePagination && items.length > 0) && (
                    <Pagination
                        itemsPerPage={itemsPerPage}
                        totalCount={totalItemsCount}
                        currentPage={currentPage}
                        setCurrentPage={handlePageNumberChange}
                    />
                )}
            </div>
        </div>
    )
}

export default PostBanner;