import React from 'react'

const CardGrid = ({ id, items }) => {
    return (
        <div className="container page-block" id={id}>
            <div className={`new-card-grid ${items.length < 4 ? 'center' : ''}`}>
                { items.map((card, idx) => (
                    <a
                        key={idx}
                        href={ !!card.link ? card.link : null }
                        className="new-card"
                        target={ card.openInNewTab ? '_blank' : '' }
                    >
                        <div className="img-container">
                            { !!card.image ? <img src={card.image} /> : null }
                        </div>
                        <div className="text">
                            { !!card.title ? <h4 className="title">{card.title}</h4> : null }
                            { !!card.description ? <p className="description">{card.description}</p> : null }
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
}

export default CardGrid