import React from 'react'
import Announcement from 'components/blocks/Announcement'
import CalloutBox from 'components/blocks/CalloutBox'
import Slider from 'components/blocks/Slider'
import Callout from 'components/blocks/Callout'
import Title from 'components/blocks/Title'
import PostDisplay from 'components/blocks/PostDisplay'
import GalleryDisplay from 'components/blocks/GalleryDisplay'
import CardGrid from 'components/blocks/CardGrid'
import Text from 'components/blocks/Text'
import Banner from 'components/blocks/Banner'
import Embed from 'components/blocks/Embed'
import Button from 'components/blocks/Button'
import Navigation from 'components/blocks/Navigation'
import Separator from 'components/blocks/Separator'
import Image from 'components/blocks/Image'
import Slideshow from 'components/blocks/Slideshow'
import Spacer from 'components/blocks/Spacer'
import Html from 'components/blocks/Html'
import PostBanner from './blocks/PostBanner'

const Renderer = ({type, lightboxes, ...data}) => {
    const selectedLightbox = lightboxes.find(l => l.id === data.lightboxId);
    if(type === "announcement") return <Announcement content={data.content} />;
    if(type === "callout") return (
        <CalloutBox
            id={data.id}
            subtitle={data.subtitle}
            title={data.title}
            content={data.content}
            imageUrl={data.imageUrl}
            linkText={data.linkText}
            linkUrl={data.linkUrl}
            openInNewTab={data.openInNewTab}
            lightbox={selectedLightbox} />
    );
    if(type === "slider") return (
        <Slider
            id={data.id}
            items={data.items}
            lightboxes={lightboxes} />
    );
    if(type === "callout_2") return (
        <Callout
            id={data.id}
            imageUrl={data.imageUrl}
            width={data.width}
            linkUrl={data.linkUrl}
            position={data.position}
            bgImageUrl={data.bgImageUrl}
            bgWidth={data.bgWidth}
            caption={data.caption}
            linkText={data.linkText}
            buttonUrl={data.buttonUrl}
            openInNewTab={data.openInNewTab}
            lightbox={selectedLightbox} />
    );
    if(type === "title") return (
        <Title
            id={data.id}
            title={data.title}
            linkType={data.linkType}
            linkText={data.linkText}
            linkUrl={data.linkUrl}
            openInNewTab={data.openInNewTab} />
    );
    if(type === "post_display") return (
        <PostDisplay
            id={data.id}
            sources={data.sources}
            numberOfItems={data.numberOfItems}
            numberOfRows={data.numberOfRows}
            numberOfColumns={data.numberOfColumns}
            order={data.order} />
    );
    if(type === "gallery_display") return (
        <GalleryDisplay
            id={data.id}
            title={data.title}
            linkText={data.linkText}
            linkUrl={data.linkUrl}
            sources={data.sources}
            numberOfItems={data.numberOfItems}
            numberOfRows={data.numberOfRows}
            numberOfColumns={data.numberOfColumns}
            order={data.order}
            layout={data.layout}
            backgroundColor={data.backgroundColor} />
    );
    if(type === "card_grid") return (
        <CardGrid
            id={data.id}
            items={data.items} />
    );
    if(type === "separator") return (
        <Separator
            size={data.size}
            color={data.color}
            backgroundColor={data.backgroundColor} />
    );
    if(type === "spacer") return (
        <Spacer
            size={data.size}
            backgroundColor={data.backgroundColor} />
    );
    if(type === "image") return (
        <Image
            id={data.id}
            text={data.text}
            imageUrl={data.imageUrl}
            position={data.position}
            verticalAlign={data.verticalAlign}
            width={data.width} />
    );
    if(type === "banner") return (
        <Banner
            id={data.id}
            imageUrl={data.imageUrl}
            caption={data.caption} />
    );
    if(type === "embed") return (
        <Embed
            id={data.id}
            embedType={data.embedType}
            embedUrl={data.embedUrl}
            width={data.width}
            height={data.height}
            position={data.position} />
    );
    if(type === "button") return (
        <Button
            id={data.id}
            text={data.text}
            url={data.url}
            openInNewTab={data.openInNewTab}
            color={data.color}
            backgroundColor={data.backgroundColor}
            position={data.position}
            lightbox={selectedLightbox} />
    );
    if(type === "navigation") return (
        <Navigation
            title={data.title}
            color={data.color}
            backgroundColor={data.backgroundColor}
            items={data.items} />
    );
    if(type === "text") return (
        <Text
            id={data.id}
            content={data.content}
            uuid={data.uuid} />
    );
    if(type === "slideshow") return (
        <Slideshow
            id={data.id}
            items={data.items} />
    );
    if(type === "html") return (
        <Html
            id={data.id}
            content={data.content}
            uuid={data.uuid} />
    );
    if(type === "post_banner") return (
        <PostBanner
            id={data.id}
            sources={data.sources}
            numberOfItems={data.numberOfItems}
            order={data.order}
            layout={data.cardLayout}
            color={data.color}
            backgroundColor={data.backgroundColor}
            itemsPerPage={data.itemsPerPage} />
    );
    return <div>{type}</div>;
}

export default Renderer