import React, { useState, useEffect, useRef, Fragment } from 'react'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const Pagination = ({
    itemsPerPage,
    totalCount,
    currentPage,
    setCurrentPage,
}) => {
    const totalPage = Math.ceil(totalCount/itemsPerPage);
    const pageNumbers = Array.from({ length: totalPage }, (_, i) => i + 1);
    const lastPage = pageNumbers.length;

    return (
        <Fragment>
            { lastPage > 1 && (
                <nav className="pagination">
                    { currentPage > 1 && (
                        <>
                            <span className="first"
                                onClick={() => setCurrentPage(1)}>
                                <DoubleArrowIcon style={{ transform: 'rotate(180deg)' }}/>
                                First
                            </span>
                            <span className="prev"
                                onClick={() => setCurrentPage(currentPage - 1)}
                            >
                                <NavigateBeforeIcon/>
                                Previous
                            </span>
                        </>
                    )}

                    { pageNumbers.map((num,) => (
                        <span key={num}
                            className={`page ${currentPage == num ? 'current' : ''}`}
                            onClick={() => setCurrentPage(num)}>{num}</span>
                    ))}

                    { currentPage != lastPage && (
                        <>
                            <span className="next"
                                onClick={() => setCurrentPage(currentPage + 1)}>
                                Next
                                <NavigateNextIcon/>
                            </span>
                            <span className="last"
                                onClick={() => setCurrentPage(lastPage)}>
                                Last
                                <DoubleArrowIcon/>
                            </span>
                        </>
                    )}
                </nav>
            )}
        </Fragment>
    )
}

export default Pagination;