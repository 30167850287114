import React, { Fragment, useState } from 'react'
import Lightbox, { displayLightbox } from './Lightbox';

const Button = ({ id, text, url, color, backgroundColor, position, openInNewTab, lightbox={} }) => {
    const lightboxId = lightbox.id || null;
    return (
        <Fragment>
            <div className="container page-block" style={{ "textAlign": position }} id={id}>
                <a
                    href={url}
                    className={`btn ${lightboxId ? 'lightbox-btn' : ''}`}
                    style={{ "color": color, "backgroundColor": backgroundColor }}
                    target={ openInNewTab ? '_blank': '' }
                    onClick={(e) => {
                        if (lightboxId) {
                            displayLightbox(e, lightboxId);
                        }
                    }}
                    // data-lightbox-id={lightboxId}
                >
                    {text}
                </a>
                <Lightbox item={lightbox}/>
            </div>
        </Fragment>
    )
}

export default Button