import React, { useState } from 'react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Slideshow = ({ id, items }) => {

    const [currentSlide, setCurrentSlide] = useState(0);
    const changeSlide = (n) => {
        if(n > items.length - 1) {
            setCurrentSlide(0);
        } else if(n < 0) {
            setCurrentSlide(items.length - 1);
        } else {
            setCurrentSlide(n);
        }
    }

    return (
        <div className="container page-block" id={id}>
            <div className="slideshow-block">
                { items.map((item, idx) => {
                    return (
                        <div key={idx}
                            className={`slide ${idx !== currentSlide ? 'd-none' : ''}`}>
                            <div className="slide-display">
                                <div className="scroll-left"
                                    onClick={() => changeSlide(currentSlide - 1)}>
                                    <span className="material-icons">chevron_left</span>
                                </div>
                                <div className="scroll-right"
                                    onClick={() => changeSlide(currentSlide + 1)}>
                                    <span className="material-icons">chevron_right</span>
                                </div>
                                <div className="slide-image">
                                    <img src={item.imageUrl} className="fade" />
                                </div>
                            </div>
                            <div className="slide-copy">
                                { !!item.title ? <h4 className="title">{item.title}</h4> : null }
                                { !!item.description ? <p className="description">{item.description}</p> : null }
                                { !!item.linkText ? (
                                    <a
                                        className="link-to"
                                        href={item.linkUrl}
                                        target={ item.openInNewTab ? '_blank' : '' }
                                    >
                                        <span className="underline">{item.linkText}</span>
                                        <span className="material-icons">chevron_right</span>
                                    </a>
                                ) : null }
                            </div>
                        </div>
                    )
                })}
                <div className="thumb-bar">
                    <ChevronLeftIcon
                        className="left"
                        onClick={() => changeSlide(currentSlide - 1)} />
                    <div className="thumbs">
                        { items.map((item, idx) => {
                            return (
                                <div className={`thumb ${idx === currentSlide ? 'active' : '' }`}
                                    key={idx}
                                    onClick={() => setCurrentSlide(idx)}>
                                    <img src={item.imageUrl} className="fade" />
                                </div>
                            )
                        })}
                    </div>
                    <ChevronRightIcon
                        className="right"
                        onClick={() => changeSlide(currentSlide + 1)} />
                </div>
            </div>
        </div>
    );
}

export default Slideshow