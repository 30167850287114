import React from 'react'

const Banner = ({ id, imageUrl, caption }) => {
    { return !!imageUrl ? (
        <div className="banner" id={id}>
            <img src={imageUrl} />
            { !!caption ? <h2 className="caption">{caption}</h2> : null }
        </div>
    ) : null }
}

export default Banner