import React, { useEffect } from 'react'

const Html = ({ id, content, uuid }) => {

    useEffect(() => {
        const htmlBlock = document.getElementById(uuid);
        htmlBlock.innerHTML = content;
    }, [content]);

    return (
        <div id={id}>
             <div className="html-block" id={uuid}></div>
        </div>
    )
}

export default Html