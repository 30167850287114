import React from 'react'
import LockIcon from '@material-ui/icons/Lock';

const Card = ({ category, title, image, url, description,
                type, badge, isExclusive, tag }) => {
    
    return (
        <a href={url}
            className={`card card-border ${ !!type ? type : '' }`}>
            <div className="image-box">
                { !!image ? <img src={image} /> : null }
                <div className="image-overlay"></div>
                { isExclusive ? (
                    <div className="badge badge-orange badge-lock">
                        <LockIcon />Paid members
                    </div>
                ) : null }
            </div>
            { !!category ? <div className="brand">{category}</div> : null }
            <div className="text">
                { !!tag ? <div className="badge">{tag}</div> : null }
                { !!title ? <h4 className="title">{title}</h4> : null }
                { !!description ? <div className="description br">{description}</div> : null }
                { !!badge ? (
                    <div className="event-badges">
                        { badge.map((b, idx) => {
                            return (
                                <div key={idx} className="badge badge-gray">
                                    { b.iconSet === 'material' ? (
                                        <span className="material-icons">{b.iconName}</span>
                                    ) : null }
                                    { b.iconSet === 'fontawesome' ? (
                                        <i className={b.iconName} />
                                    ) : null }
                                    {b.name}
                                </div>
                            )
                        })}
                    </div>
                ) : null }
            </div>
        </a>
    );
}

export default Card
